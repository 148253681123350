<script setup lang="ts">
import type { VoixFieldsData } from '@voix/types'
import { defineSlice } from '#imports'

const props = defineProps<{
  fields: VoixFieldsData
}>()

defineSlice({
  name: {
    label: 'Staff Card',
    group: 'Slices',
  },
  description: 'A staff card',
  fields: {
    name: {
      type: 'text',
      label: 'Name',
      default: 'John Doe',
    },
    showName: {
      type: 'checkbox',
      label: 'Show Name',
      default: true,
    },
    hired: {
      type: 'datetime',
      label: 'Hired',
      default: 'Jan 1st 2020',
      format: 'MMMM Do YYYY',
      date: true,
    },
    media: {
      type: 'media',
      label: 'Media',
      width: 300,
      height: 500,
      default: 'https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80',
      breakpoints: {
        sm: {
          width: 200,
          height: 200,
        },
        lg: {
          width: 300,
          height: 500,
        },
      },
      allowedMedia: ['image', 'video'],
    },
  },
})
</script>

<template>
  <div class="flex flex-col items-center">
    <div
      class="group relative w-[200px] h-[200px] lg:w-[300px] lg:h-[500px] rounded-xl overflow-hidden bg-gray-700"
    >
      <VoixMedia
        :field="props.fields.media"
        class="object-cover w-full h-full opacity-100 blur-sm scale-125 group-hover:blur-none group-hover:scale-110 transition duration-500"
      />
      <div class="absolute bottom-0 left-0 right-0">
        <div class="flex justify-center">
          <div
            class="relative w-[100px] h-[100px] rounded-full shadow-lg bg-gray-500 overflow-hidden group-hover:opacity-0 group-hover:scale-50 transition duration-250 border-2 border-zinc-300"
          >
            <VoixMedia
              v-slot="slotProps"
              :field="props.fields.media"
              :background="true"
              :lazy="true"
            >
              <div
                class="absolute z-10 inset-0 bg-cover bg-center w-full h-full"
                :style="{
                  backgroundImage: `url(${slotProps.image})`,
                }"
              />
            </VoixMedia>
          </div>
        </div>

        <div
          v-if="fields.showName.value"
          class="text-xl uppercase font-bold text-primary text-center py-4"
        >
          {{ fields.name.value }}
          <div class="text-sm uppercase text-white text-center font-normal">
            Since {{ fields.hired.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
